import { Component, Inject, Input } from '@angular/core';

import { OktaAuth } from '@okta/okta-auth-js';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { ElementRef, HostListener } from '@angular/core';
import { OktaAuthService } from '../shared/okta/okta.service';
import { ActivatedRoute} from '@angular/router';

import { Subscription } from 'rxjs';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  @Input() item = ''; // decorate the property with @Input()
  currenti = this.item as string;
  path = '';
  username = '';
  subscription!: Subscription;
  title: any;
  userOHR: any;
  ngOnInit() {
    this.getOHR();
    this.username = localStorage.getItem('okta-username') ? localStorage.getItem('okta-username') as string : 'Guest';
    this.route.params.subscribe(params => {
      const currentRoute = this.router.url;

      if (currentRoute === '/bgc') {
        this.title = 'BGC Report';
      } else if (currentRoute === '/soc-report') {
        this.title = '';
      }
      else if (currentRoute === '/Home') {
        this.title = 'Konverse';
      }
      else if (currentRoute === '/meeting-assist') {
        this.title = 'MeetingAssist';
      }
      else if (currentRoute === '/g-chat') {
        this.title = '';
      }
      // Add more else if conditions for other routes
    });

  }
  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        this.userOHR = ohr_id;
      }
    }
  }
  // isUserOHRValid(): boolean {
  //   // Replace the arrayValues with your actual array of valid OHR values
  //   const validOHRValues: string[] = ['703157733', '703042528', '703021804', '703022515', '703267043', '400215104', '703341684', '850064135']

  //   return validOHRValues.includes(this.userOHR);
  // }
  check():string {
    if (this.currenti === 'All') {
      this.path = '/demos';
    } else {
      this.path = '/playground';
    }
    return this.path;
  }
  constructor(@Inject(OKTA_AUTH)public oktaAuth: OktaAuth,
  private router: Router,
  private route: ActivatedRoute,
  private elementRef: ElementRef,
  private oktaService:OktaAuthService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; }; }) {
    let screenWidth = event.target.innerWidth;
    let container = this.elementRef.nativeElement.querySelector('.res-container');

    if(container){
      if (screenWidth < 768) {
        container.style.width = '100%';
      } else {
        container.style.width = '768px';
      }
    }
  }
  logout() {

    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('okta-username');
    localStorage.removeItem('token');
    localStorage.clear();
    sessionStorage.clear();
    this.oktaService.logout();
    this.router.navigate(['logout']);
  }
  hide() {
    const animated = document.getElementsByClassName("animated-icon");
    this.toggleClass('open', animated[0]);
    const el = document.getElementById("mySidenav") as HTMLElement;
    el.style.width = "0px";
  }
  clo(){
    const el = document.getElementById("mySidenav") as HTMLElement;
    const animated = document.getElementsByClassName("animated-icon");
    var width = el?.style.width;
    if (width === "250px" || width == "") {
      el.style.width = "0px";
      this.toggleClass('open', animated[0]);
    }
    else {
      el.style.width = "0px";
      this.toggleClass('open', animated[0]);
    }
   }
  Nav() {
    const el = document.getElementById("mySidenav") as HTMLElement;
    const animated = document.getElementsByClassName("animated-icon");
    var width = el?.style.width;
    if (width === "0px" || width == "") {
      el.style.width = "250px";
      this.toggleClass('open', animated[0]);
    }
    else {
      el.style.width = "0px";
      this.toggleClass('open', animated[0]);
    }
  }
  toggleClass(className: string, el: any) {
    const hasClass = el.classList.contains(className);
    if (hasClass) {
      el.classList.remove(className);
    } else {
      el.classList.add(className);
    }
  }

   downloadFile() {
    const filePath = 'assets/images/zoom-bg.zip'; // Replace with your file path in the assets folder

    fetch(filePath)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'zoom-bg.zip'; // Set the file name for download
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        // Handle error if download fails
        console.error('Download failed:', error);
      });
  }
}
