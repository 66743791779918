import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PdfSummaryService {

  constructor(public httpService: HttpClient) { }

  Accesspoint = environment.apiURL;
  nodeEndpoint = environment.nodeEndpoint;

  useraccesscheck(): Observable<any> {
    //http post request with payload
    return this.httpService.post(`${this.nodeEndpoint}validate-role`, {
     'process_value':"document-summary",
    });

   }

  submit(formData:any){
    // return this.httpService.post('http://localhost:8000/qa', formData);
    return this.httpService.post(`${this.Accesspoint}/devflow/qa`, formData);
  }
  fileUploadStatus(formData:any){
    // return this.httpService.post('http://localhost:8000/qa', formData);
    return this.httpService.post(`${this.Accesspoint}/devflow/status`, formData);
  }
  clearKnowledgeBaseApi(){
    return this.httpService.post(`${this.Accesspoint}/devflow/clear_knowledge_base`, '',);
  }
  

  uploadFilePrompt(formData:any, param:any){
    return this.httpService.post(`${this.Accesspoint}/devflow/uploadprompt?doctype=${param}`, formData);
  }
  getLogsFileList(param:any){
    return this.httpService.get(`${this.Accesspoint}/devflow/getfiles?doctype=` +  param );
  }
  downloadLogsFile(param1:any,param2:any){
    return this.httpService.get(`${this.Accesspoint}/devflow/download?file_name=`+param1+ '&doctype='+param2 ,{ responseType: 'blob' });
  }
  getDocTypeList(){
    return this.httpService.get(`${this.Accesspoint}/devflow/doctypeLOV`);
  }
  adminRoleView(){
    return this.httpService.get(`${this.Accesspoint}/devflow/adminViewLOV`);
  }
  promptCatagory(param:any){
    return this.httpService.get(`${this.Accesspoint}/devflow/promptCatagoryLOV?doctype=`+param);
  }


// qaApi3(formData2:any)
// {
// return this.httpService.post(`${this.Accesspoint}/devflow/submit`, formData2)
// }
}
