import { Component, OnInit , Renderer2, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import * as XLSX from "xlsx-js-style";
import { ViewChild } from '@angular/core'
import { ElementRef } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { PdfSummaryService } from 'src/app/shared/service/pdf-summary.service';
import { Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pdfsummary',
  templateUrl: './pdfsummary.component.html',
  styleUrls: ['./pdfsummary.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PdfsummaryComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<any>;
  loaderSpinner = false;
  tableHeaders:any;
  myFiles: any[] = [];
  sMsg: string = '';
  textareaValue: string = '';
  apiResponseData: any;
  apiResponseText: string = '';
  chatQns: string[] = [];
  chatAns:any= [];
  fileName = 'ExcelSheet.xlsx';
  vFile: any = '';
  value: string = '';
  selectedDropDownValue: any = 'Select';
  promptFile: any = 'assets/test.xlsx';
  errorMsg:string='error';
  errorFlag :boolean=false;
  qaAns:any = [];
  firstTimeDataInFinanace =  false
  promptAns:any= [];
  fileNames:any;
  selected:any = "Select";
   financialFunctional:any = []
  // financialFunctionalData:any = [
  //   'Contracting entities Information',
  //   'Contract lifecycle data',
  //   'Contract commercials (clauses)',
  //   'Billing related',
  //   // 'Dispatch Information'
  // ];
    category: any = 'Select';
  categoryMessage!: string;
  files: FileList | null = null;
  isFileUploaded = false;
  fileUploadSuccessfully =  false;
  userAccessStatus: boolean = false;
  hasPermission: any;
  showView =  true;
  docTypeList = [];
  viewType = '';
    constructor(private el: ElementRef,
      private toastr: ToastrService,
       private pdfSummaryService: PdfSummaryService,
       public router: Router, private  elementRef: ElementRef, private renderer: Renderer2,
       ) 
       { }

  ngOnInit(): void {
     this.accesscheck();
     this.clearKnowledgeBase();
     this.getDocList();
  }
  
  getDocList(value?:any){
    this.pdfSummaryService.getDocTypeList().subscribe((val:any)=>{
      if(val){
        this.docTypeList = val['doctype']
      }
    },(error: any) => {
      console.error('An error occurred:', error);
    })
  }
  getPromptList(){
    let param = ''
    if(this.selectedDropDownValue == "Finance Functional"){
      param = "financeFunctional"
    } else {
      param = this.selectedDropDownValue
 
    }
    this.pdfSummaryService.promptCatagory(param).subscribe((val:any)=>{
      if(val){
        this.financialFunctional = val['Data']
      }
    },(error: any) => {
      console.error('An error occurred:', error);
    }) ;
  }
  clearKnowledgeBase(value?:any){
    this.pdfSummaryService.clearKnowledgeBaseApi().subscribe((val:any)=>{
      if(value){
        this.uploadFiles();
      }
    },(error: any) => {
      console.error('An error occurred:', error);
    })
  }

  accesscheck() {
    this.loaderSpinner = true;
    this.pdfSummaryService.useraccesscheck().subscribe((res: any) => {
      if (res?.response?.status === "success") {
       // this.chkagree = true;
       this.loaderSpinner = false;
       this.userAccessStatus = true;
       this.hasPermission = true;
 
       if(this.hasPermission){
          this.showView = true;
       }
       console.log(this.userAccessStatus);
        this.toastr.success(res?.response?.message);
      }
      else {
        //this.chkagree = false;
        this.loaderSpinner = false;
        this.hasPermission = false;
        if(!this.hasPermission){
          this.showView = false;
       }
        this.toastr.error(res?.response?.message);
      }
    },
    (error) => {
      console.log(error);
      // this.loaderService.loading.next(false);
      this.toastr.error(error?.error?.response?.message);
    }
  
    );
  }
  onFileSelected(e: any) {
      // Check if any files were selected
      if (e.target.files.length > 0) {
        this.myFiles = [];
        this.chatQns = [];
        this.chatAns = [];
        const allowedExtensions = ['pdf', 'docx', 'xlsx', 'xlsb', 'txt'];
        for (var i = 0; i < e.target.files.length; i++) {
          const file = e.target.files[i];
          const extension = this.getFileExtension(file.name);
          if (allowedExtensions.includes(extension.toLowerCase())) {
              this.myFiles.push(file);
          } else {
              // Handle invalid file type here (e.g., show an error message)
              this.fileNames = ''
              alert("Please upload file of type:  pdf, docx, xlsx, xlsb and text file ");
              return
          }
            // this.myFiles.push(e.target.files[i]);
        }
        if (this.myFiles.length == 1) {
            this.fileNames = this.myFiles.map(function (file) {
                const parts = file.name.split('.');
                if (parts.length > 1) {
                    const extension = parts.pop();
                    const nameWithoutExtension = parts.join('.');
                    if (nameWithoutExtension.length > 5) {
                        return nameWithoutExtension.slice(0, 20 - 3) + '...' + extension;
                    } else {
                        return file.name;
                    }
                } else {
                    return file.name;
                }
            }).join(',');
        } else {
            this.fileNames = `multiple files: ${this.myFiles.length}`;
        }
    }
  }

  getFileExtension(filename: string): string {
    const parts = filename.split('.');
    if (parts.length > 1) {
        return parts.pop()!.toLowerCase();
    }
    return '';
}
  uploadFiles() {
    if (this.selectedDropDownValue == '' || this.selectedDropDownValue == 'Select') {
        this.errorFlag =true;
        this.errorMsg='Kindly Select Document type';
        this.toastr.error(`Kindly Select Document type`,'Error!');
        return;
    } else {
      this.errorFlag =false;
      this.errorMsg='';
    }
    if (this.myFiles.length == 0) {
      this.toastr.error(`Kindly choose at least one file`,'Error!');
      return;
    }
    if(this.validatefileType() == 'largeSize' || this.validatefileType()  == 'fileTypeIssue'){
      this.toastr.error(`File size should be less than 16 MB`, `Error!`);
      return;
     } 
    if(this.category){
     this.category = 'Select';
    }
    const formData = new FormData();
    if(this.selectedDropDownValue == 'Document'){
      formData.append("doctype", "null");
    }
    if(this.selectedDropDownValue == 'NDA Review'){
      formData.append("doctype", "NDA Review");
    }
    for (var i = 0; i < this.myFiles.length; i++) {
      formData.append("files", this.myFiles[i]);
    }

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('dropDownValue', this.selectedDropDownValue);
    this.loaderSpinner = true;
      this.pdfSummaryService.fileUploadStatus(formData).subscribe(
     (data:any) => {
        // SHOW A MESSAGE RECEIVED FROM THE WEB API.
        this.sMsg = data as string;
        if (data?.status.toLowerCase() === 'error') {
          this.loaderSpinner = false;  
          this.toastr.error('There is an error in loading embeddings', 'Error!');   
          return      
        } else if (data?.status == 'Not Created'){
        setTimeout(() => {
          this.uploadFiles();
        }, 10000);
      }
      else if(data?.status == 'Created'){
        this.apiResponseData = data;
        this.apiResponseText = this.apiResponseData['message'];
        this.isFileUploaded = true;
        this.fileUploadSuccessfully = true;
        this.chatQns = [];
        this.chatAns = [];
        this.promptAns= [];
          this.loaderSpinner = false;
          if(this.myFiles.length == 1) {
            this.toastr.success(`${this.fileNames}`,'Success!');
          } else {
            this.toastr.success(`Uploaded file: ${this.fileNames}`,'Success!');
          }
          if(this.selectedDropDownValue == 'NDA Review'){
            this.value = 'Does this document contain any of the above mentioned governing law? Just answer Yes or No';
            this.submitQuery()
          }
      }
      },(err: any) => {
        this.clearKnowledgeBase();
        this.toastr.error(err?.error?.message, `Error!`);
        this.loaderSpinner = false
      }
    );
  }

  //Qns api call
  submitQuery() {
    this.loaderSpinner = true;
    this.firstTimeDataInFinanace = false;
    this.chatQns.push(this.value);
    const formData = new FormData();
    formData.append("doctype", 'null');
    formData.append("query", this.value);
    formData.append("category_value", 'null');
    this.pdfSummaryService.submit(formData).subscribe( (data:any) => {
        this.sMsg = data as string;
        this.apiResponseData = data,
        this.apiResponseText = this.apiResponseData['answer'];
        this.textareaValue = this.textareaValue + this.apiResponseText;
        this.chatAns.push(this.apiResponseText);
        this.scrollDown();
        this.loaderSpinner = false;
      },(error:any) => {
        this.chatAns.push('Error');
        this.loaderSpinner = false;
      }
    );
    this.value = '';
  }

  ExportToExcel() {
    this.fileName = this.category+'.xlsx';
    this.loaderSpinner = true;
    let  data:any = [];
  
    
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
      if (this.promptAns && this.promptAns.length > 0 && this.promptAns[0].value && this.promptAns[0].value.length > 0) {
      let data: any = [];
      const tableHeaders = Object.keys(this.promptAns[0].value[0]);
       data = [tableHeaders];
      this.promptAns[0].value.forEach((row: any) => {
        const rowData = Object.values(row);
        data.push(rowData);
      });
      this.chatQns.forEach((val, index) => {
        const value = [val, this.chatAns[index]];
        data.push(value);
      });
      
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
      
      const wscols: XLSX.ColInfo[] = [
        { wch: 50 }, // Width of column A (10 characters)
        { wch: 50 }, // Width of column B (20 characters)
        { wch: 50 }  // Width of column C (15 characters)
      ];
      
      // Set column width
      ws['!cols'] = wscols;
      
      // Enable text wrap for each cell
      let minRow = Number.MAX_VALUE;
      let minCol = Number.MAX_VALUE;
      let maxRow = 0;
      let maxCol = 0;
      data.forEach((row:any, rowIndex:any) => {
        row.forEach((cell:any, colIndex:any) => {
          const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
          const cellObj = ws[cellAddress];
          if (cellObj && cellObj.t === 's') {
            const cellStyle = {
              alignment: { wrapText: true,vertical: 'top' },
              font: { sz: 12 }, // Optional: Set font size
            };
            cellObj.s = cellStyle;
          }
          if (cell !== null && cell !== undefined) {
            minRow = Math.min(minRow, rowIndex);
            minCol = Math.min(minCol, colIndex);
            maxRow = Math.max(maxRow, rowIndex);
            maxCol = Math.max(maxCol, colIndex);
          }
        });
      });
      
      // Set the range of the worksheet
      const range: XLSX.Range = {
        s: { r: minRow, c: minCol },
        e: { r: maxRow, c: maxCol },
      };
      ws['!ref'] = XLSX.utils.encode_range(range);
      
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.fileName);
      
    } else {
      this.chatQns.forEach((val,index)=>{
        const value = []
        value.push(val);
        value.push(this.chatAns[index])
        data.push(value)
     })
   const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data,{cellStyles: true});
   const columnWidths = [0, 0];
   XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row:any) => {
   row.forEach((cell:any, colIndex: number) => {
     const cellText = String(cell);
     const cellWidth = cellText.length;
     if (cellWidth > columnWidths[colIndex]) {
       columnWidths[colIndex] = cellWidth;
       }
     });
   });
   const wscols: XLSX.ColInfo[] = [
    { wch: 50 }, // Width of column A (10 characters)
    { wch: 50 }, // Width of column B (20 characters)
  ];
  ws['!cols'] = wscols;
  
      
      // Enable text wrap for each cell
      let minRow = Number.MAX_VALUE;
      let minCol = Number.MAX_VALUE;
      let maxRow = 0;
      let maxCol = 0;
      data.forEach((row:any, rowIndex:any) => {
        row.forEach((cell:any, colIndex:any) => {
          const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
          const cellObj = ws[cellAddress];
          if (cellObj && cellObj.t === 's') {
            const cellStyle = {
              alignment: { wrapText: true,vertical: 'top' },
              font: { sz: 12 }, // Optional: Set font size
            };
            cellObj.s = cellStyle;
          }
          if (cell !== null && cell !== undefined) {
            minRow = Math.min(minRow, rowIndex);
            minCol = Math.min(minCol, colIndex);
            maxRow = Math.max(maxRow, rowIndex);
            maxCol = Math.max(maxCol, colIndex);
          }
        });
      });
      
      // Set the range of the worksheet
      const range: XLSX.Range = {
        s: { r: minRow, c: minCol },
        e: { r: maxRow, c: maxCol },
      };
      ws['!ref'] = XLSX.utils.encode_range(range);
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     XLSX.writeFile(wb, this.selectedDropDownValue+'.xlsx');
    }
     this.loaderSpinner = false;
  }

  validatefileType(): string {
    let extension = '';
    let vflag = 'fileTypeIssue';
    for (var i = 0; i < this.myFiles.length; i++) {
      this.vFile = this.myFiles[i];
      const splitParts = this.vFile.name.split('.');
      extension = splitParts[splitParts.length - 1].toLowerCase();
      if (extension == 'pdf' || extension == 'docx' || extension == 'xlsx' || extension == 'xlsb' || extension == 'txt') {
        if (this.vFile.size > 16e+6) {
          return 'largeSize';
        }
        vflag = 'fileTypeNoIssue';
      }

      else {
        return 'fileTypeIssue';
      }
    }
    return vflag;
  }

 onSelected(value: string): void {
    this.selectedDropDownValue = value;
  }
  selectDocType(event:any, type:any){
    
    if(type == 'docType'){
      console.log(event);
      this.getPromptList();
      this.chatQns = [];
      this.chatAns = [];
      const inputElement = this.fileInput.nativeElement;
      inputElement.value = ''; // This clears the selected file
      this.myFiles = [];
      this.promptAns= [];
      this.category = 'Select';
      this.financialFunctional = [];
      this.fileNames = '';
      this.categoryMessage = '';
      // if(event == 'Finance Functional'){
      //   this.getPromptList();
      //   // this.financialFunctional = [...this.financialFunctionalData];
      //   this.category = 'Select';
      //   this.chatQns = [];
      //   this.chatAns = [];
      //   const inputElement = this.fileInput.nativeElement;
      //   inputElement.value = ''; // This clears the selected file
      //   this.myFiles = [];
      //   this.fileNames = '';
      // }
      //  else {
      //   this.chatQns = [];
      //   this.chatAns = [];
      //   const inputElement = this.fileInput.nativeElement;
      //   inputElement.value = ''; // This clears the selected file
      //   this.myFiles = [];
      //   this.promptAns= [];
      //   this.category = 'Select';
      //   this.financialFunctional = [];
      //   this.fileNames = '';
      //   this.categoryMessage = '';

      // }
    } 
  }
  categorySubmit(category:any){
    this.getqaAPIForExport(category);
  }
  getqaAPIForExport(category?:any){
    
    if (this.myFiles.length == 0 || !this.isFileUploaded)  {
      this.toastr.error(`Kindly choose at least one file`,'Error!');
      return;
    }
    if(category){
      this.chatQns = [];
      this.chatAns = []
    }
    this.loaderSpinner = true;
     const formData = new FormData();
     formData.append("doctype", 'financeFunctional');
     formData.append("query", 'null');
     formData.append("category_value", this.category);
     this.promptAns= [];
     this.pdfSummaryService.submit(formData).subscribe( (data:any) => {
      if(data?.['answer'] == 'No response is found in the list'){
        setTimeout(() => {
          this.getqaAPIForExport();
         }, 10000);
      } else if (data?.['answer'] == 'Error') {
        this.loaderSpinner = false;  
        this.toastr.error('There is an error in Responses.Please try again', 'Error!');   
        return      
      } else {
        if(data && Array.isArray(data['answer']) && data['answer'].length > 0){
          this.firstTimeDataInFinanace = true;
          this.promptAns.push({'type':'finance', 'value':data['answer']});
          this.tableHeaders = Object.keys(data['answer'][0]);
         } else {
            this.chatQns.push(this.category);
            this.firstTimeDataInFinanace = false;
            this.chatAns.push('Category does not exists in prompt file.');       
          }
          this.categoryMessage = '';
          this.loaderSpinner = false; 
      }
     
      }, (error:any) => {
        this.clearKnowledgeBase();
        this.loaderSpinner = false;
        this.toastr.error(error?.error['message'], 'Error!');

      }
    );
  }
  scrollDown() {
    const element = this.elementRef.nativeElement.querySelector('#summary-content-scroll');
  if (element) {
    element.scrollTop = element.scrollHeight;
  } else {
    console.error('Element not found with ID: your-element-id');
   }
  }
}
