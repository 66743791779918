
  <!-- Top Navbar -->
  <nav class="main-header navbar navbar-expand navbar-light docsum" style="background:url(assets/images/headerbg.png);background-size: cover;">
    <!-- Left navbar links -->
    <img src="../../../../assets/images/glogo.png" alt="Responsive Image" class="img-fluid" [routerLink]="['/Home']">
    <br>
        <span class="headertext text-skyblue ml-2" style="font-family: GuardianSansRegular; font-size: 20px;"><strong>Finance | AI Document  Summarization</strong></span>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto link">
      <li  class="nav-item user-panel d-flex align-items-sm-center"  *ngIf="viewTypeRole == 'admin'">
        <select class="custom-select custom-select-header" [(ngModel)]="dropdownType"  (click)="promptFileAction(dropdownType)"
        (change)="selectType(dropdownType)">
        <option [value]="'Select'" [selected]="dropdownType === 'Select'" disabled>Admin</option>
        <option value="upload">Upload</option>
        <option value="download">Download</option>
      </select>
      </li>
      <li class="nav-item user-panel d-flex">
        <div class="info">
            <a  class="d-block text-white" (click)="home()">Home <i class="fa fa-home" aria-hidden="true"></i></a>
        </div>
    </li>
      <li class="nav-item user-panel d-flex">
          <div class="info">
              <a  class="d-block text-white" (click)="logout()">Logout <i class="fa fa-sign-out" aria-hidden="true"></i></a>
          </div>
      </li>
    </ul>
  </nav>
  <!-- /TOp navbar -->

  <!-- Main Sidebar Container -->
  <!-- <aside class="bg-blue main-sidebar"> -->
    <!-- Brand Logo -->
    <!-- <a href="index.html" class="brand-link" style="display: flex;">
      <span class="col-sm-2"><img src="assets/images/logos.png" alt="AdminLTE Logo" class="brand-image elevation-3" style="opacity: .8">
    </span><span class="col-sm-10 brand-text font-weight-bold"></span>
    </a> -->

    <!-- Sidebar -->
    <!-- <div class="sidebar">
     -->

      <!-- Sidebar Menu -->

      <!-- <nav class="mt-5">

<ul class="nav nav-pills nav-sidebar flex-column" id="nav-links">
  <ng-container *ngFor="let item of menuSidebar; let index = index">
    <li class="nav-item" *ngIf="!item.children" (click)="changeRoute(item, 'no-child')">
      <a routerLinkActive="active" [routerLink]="[item.link]" class="nav-link" >
        <i [class]="item.icon" class="mr-2" aria-hidden="true"></i>
        <p>{{item.link_name}}</p>
      </a>
    </li> -->
    <!-- <li class="nav-item" *ngIf="item.children" >
      <a class="nav-link" (click)="toggleSubmenu(item, 'hasChild')" [ngClass]="{ 'active': !(item && item.isCollapsed) }">
        <i [class]="item.icon" class="mr-2" aria-hidden="true"></i>
        <p>{{item.link_name}}</p>
      </a>
      <ul class="nav nav-treeview" [ngStyle]="{ 'display': item && item.isCollapsed ? 'none' : 'block' }">
        <li class="nav-item" *ngFor="let item_sub of item.children; let i = index" >
          <a routerLinkActive="active" class="nav-link" [routerLink]="[item_sub.link]" (click)="showSubmenu(item_sub, 'no-sub-child', i)">
            <p>{{item_sub.link_name}}</p>
          </a>
        </li>
      </ul>
    </li> -->


    <!-- Check if a sub-child is active, then don't collapse the parent -->
    <!-- <ng-container *ngIf="item.active && item.children && item.children.length > 0 && item == menuSidebar[index]">
      <ng-container *ngIf="hasActiveSubChild(item)">
        {{ item.isCollapsed = false }}
      </ng-container>
    </ng-container> -->
  <!-- </ng-container>
</ul> -->

        <!-- <ul class="nav nav-pills nav-sidebar flex-column" id="nav-links">
          <ng-container *ngFor="let item of menuSidebar; let index =index">
            <li class="nav-item" *ngIf="!item.children" >
              <a routerLinkActive="active" [routerLink]="[item.link]"  class="nav-link" (click)="showSubmenu(item, 'no-child')">
                <i [class]="item.icon" class="mr-2" aria-hidden="true"></i>
                <p>  {{item.link_name}} </p>
              </a>
            </li>

            <li class="nav-item"  *ngIf="item.children" >
              <a  class="nav-link" (click)="showSubmenu(item, 'hasChild',index)">
                <i [class]="item.icon" class="mr-2" aria-hidden="true"></i>
                <p> {{item.link_name}}{{item.isCollapsed}}</p>
              </a>

              <ul class="nav nav-treeview"[ngStyle]="{ 'display': item && item.isCollapsed ? 'block' : 'none' }" >
                <li class="nav-item" *ngFor="let item_sub of item.children; let i =index">
                  <a  routerLinkActive="active" class="nav-link" [routerLink]="[item_sub.link]" (click)="showSubmenu(item_sub, 'no-sub-child', i)">

                    <p>{{item_sub.link_name}}</p>
                  </a>
                </li>
              </ul>
            </li>

          </ng-container>

        </ul> -->

      <!-- </nav> -->
      <!-- /.sidebar-menu -->
    <!-- </div> -->
    <!-- /.sidebar -->
  <!-- </aside> -->
<!-- Main Sidebar Container -->
  <!-- Content Wrapper. Contains page content -->

  <!-- /.content-wrapper -->



<div class="modal fade"  #testModal id="testModal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body tcolor">
        <div class="row">
          <div class="col-12 text-decoration-underline fw-bold text-primary">Important Instructions</div>
        </div>
        <div  class="row">
          <div  class="col-12 pt-2" style="font-size: 13px;">
            <ol style="list-style-type: disc !important;">
              <li> Please be aware that the messages have been generated by an artificial intelligence model. While efforts have been made to provide accurate and relevant information, there is a possibility of errors or inaccuracies. The response you receive should not be considered as professional or expert advice. We strongly recommend cross-checking the information with reliable sources or consulting a human expert when necessary. The purpose of this AI is to assist and provide general information, but it may not always be perfect. Use the information at your own discretion. </li>
              <li> The AI-generated response will be limited to 1,000 characters to ensure concise and focused answers. </li>
              <li> If you have any inquiries, we kindly encourage you to consult Genpact's Code of Conduct Document and <a href="https://prodapps.genpact.com/RSAarcher/default.aspx?requestUrl=../Foundation/workspaceDashboard.aspx?workspaceId%3d4" target="_blank">
                ChatGPT and Use of AI Programs Policy</a>. </li>
                <li> Kindly note that your inputs maybe subject to audit, and may be used for refining the accuracy of responses provided by the BOT </li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div  class="col-12">
              <input  [(ngModel)]="isChecked"  id="byod_check_label" type="checkbox" class="form-check-input" (change)="acceptTermsCondition($event)" autocomplete="off">
              <label for="byod_check_label" class="form-check-label text-decoration-underline fw-bold text-primary" style="margin-left: 30px;">
                 I acknowledge that I have read, understand and agree to above instructions. </label>
                </div>
              </div>
      </div>
      <div class="modal-footer">
        <button type="button" [disabled]="!isChecked"  class="btn btn-primary" (click)="submit()">Proceed</button>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #chat_compilation_policy_model>
  <div class="modal-body tcolor">
    <div class="row">
      <div class="col-12 text-decoration-underline fw-bold text-primary">Important Instructions</div>
    </div>
    <div class="row">
      <div class="col-12 pt-2" style="font-size: 13px;">
        <ul>
          <li>
            Please be aware that the messages have been generated by an artificial intelligence model. While
            efforts have been made to provide accurate and relevant information, there is a possibility of
            errors or inaccuracies. The response you receive should not be considered as professional or
            expert advice. We strongly recommend cross-checking the information with reliable sources or
            consulting a human expert when necessary. The purpose of this AI is to assist and provide general
            information, but it may not always be perfect. Use the information at your own discretion.
          </li>
          <li>
            The AI-generated response will be limited to 1,000 characters to ensure concise and focused
            answers.
          </li>
          <li>
            If you have any inquiries, we kindly encourage you to consult Genpact's Code of Conduct Document
            and <a
              href="https://prodapps.genpact.com/RSAarcher/default.aspx?requestUrl=../Foundation/workspaceDashboard.aspx?workspaceId%3d4"
              target="_blank">ChatGPT and Use of AI Programs Policy</a>.
          </li>
          <li>
            Kindly note that your inputs maybe subject to audit, and may be used for refining the accuracy of
            responses provided by the BOT

          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 ml-4">
        <input id="byod_check_label" type="checkbox" [(ngModel)]="isChecked" class="form-check-input" />
        <label class="form-check-label text-decoration-underline fw-bold text-primary" style="margin-left: 15px;"
          for="byod_check_label">
          I acknowledge that I have read, understand and agree to above instructions.
        </label>
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn button-genpact text-white  cursor-pointer" [disabled]="!isChecked"
      (click)="modalRef?.hide()">
      Proceed
    </button>
  </div>
</ng-template> -->
<div class="modal fade"  [class.show]="showModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog">
	  <div class="modal-content">
		<div class="modal-header">
			<div class="modal-title fw-bold" id="exampleModalLabel">{{this.viewType == 'upload' ? 'Upload' : 'Download'}} File</div>
			<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
			  <span aria-hidden="true">&times;</span>
			</button>
		  </div>
		<div class="modal-body tcolor">
      
		  <div class="row ">
			  <div class="col-md-12 logs-files-list">
          <ng-container *ngIf="this.viewType == 'upload'">
          <div class="text-right col-md-12 prompt-section">
            <div id="uploadContainer" style="display: flex; flex-direction: column;">
              <div style="display: flex; align-items: center;">
                <input type="file" id="fileInputPrompt" #fileInputPrompt multiple style="opacity: 0; position: absolute; top: 0; left: 0;"
                     (change)="handleFileInputLogs($event)"> 
                   <button tooltip="Upload files to translate"
                     style="border-radius:0px;background:#ffffff!important;color: #073262!important; width: 100%; "
                     (click)="fileInputPrompt.click()" type="button" id="yourButton" class="btn btn-primary py-1 btn-block">
                     <span class="button-text" style="color: #073262;">
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-upload" viewBox="0 0 16 16">
                         <path
                           d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                         <path
                           d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                       </svg>
                       Choose File
                     </span>
                   </button>
              </div>
              <div *ngIf="selectedFilesPrompt && selectedFilesPrompt.length > 0" id="fileNameContainer"  class="mt-3">
                <ng-container *ngIf="selectedFilesPrompt.length === 1; else pluralFiles">
                  Selected file: {{ selectedFilesPrompt[0].name }}
                </ng-container>
                <ng-template #pluralFiles>
                  {{ selectedFilesPrompt.length }} files selected
                </ng-template>
              </div>
            </div>
          </div>
        </ng-container>
				<ul  *ngIf="this.viewType == 'download'" class="log-file-list">
					<li class="cursor-pointer" style="text-decoration: underline;" *ngFor="let data of logFileList" (click)="downloadFile(data)">{{data}}</li>
				</ul>
			  </div>
		  </div>	
		</div>
		<div class="modal-footer">
		  <button type="button"   class="btn btn-primary" (click)="closeModal()">Close</button>
		</div>
	  </div>
	</div>
  </div>