import { Injectable } from '@angular/core';
import * as microsoftTeams from '@microsoft/teams-js';
import { BehaviorSubject } from 'rxjs';
import { jwtDecode } from 'jwt-decode';



@Injectable({
  providedIn: 'root'
})
export class TeamsAuthService {

  isTeamsEnvironment: boolean;
  teamsPlatform: any;
  currentPlatform = new BehaviorSubject<any>('web');
  defaultPlatform = 'web';

  constructor() {
    this.isTeamsEnvironment = window.self !== window.top; // Checks if the app is running inside an iframe

    if (this.isTeamsEnvironment) {
      try {
        microsoftTeams.initialize();
        this.getTeamsStatus();
      } catch (error) {
        console.log('Error initializing Teams:', error);
        this.isTeamsEnvironment = false;
      }
    }
  }


  getTeamsStatus(): any {
    // this.updateCss();
    this.isTeamsEnvironment = window.self !== window.top; // Checks if the app is running inside an iframe

    return new Promise((resolve, reject) => {
      if (this.isTeamsEnvironment) {
        try {


          // Check if Microsoft Teams SDK is available
          if (typeof microsoftTeams === 'undefined') {
            this.teamsPlatform = 'Microsoft Teams SDK not available. Make sure the app is running within Teams.';
            console.error('Microsoft Teams SDK not available. Make sure the app is running within Teams.');
            return;
          }

          // Initialize Microsoft Teams SDK
          microsoftTeams.initialize();

          // Get context from Microsoft Teams
          microsoftTeams.getContext(async (context: any) => {
            this.teamsPlatform = context.hostClientType;

            const platformLogs: any = {
              desktop: 'teams_tab',
              web: 'teams_tab',
              android: 'teams_tab',
              ios: 'teams_tab',
              default: 'web',
            };

            // Log information based on the Teams platform
            this.defaultPlatform = platformLogs[this.teamsPlatform] || platformLogs.default;
            this.currentPlatform.next(this.defaultPlatform);

            if (this.defaultPlatform == 'teams_tab') {
              this.updateCss();
              let getToken = await this.getAuthToken();
              console.log('token get in async ', this.defaultPlatform);
              if (getToken) resolve(this.defaultPlatform);
              return;
            } else {
              console.log(this.defaultPlatform);
              resolve(this.defaultPlatform);
            }

          });
        } catch (error) {
          console.log('Error initializing Teams:', error);
          this.isTeamsEnvironment = false;
          resolve(this.defaultPlatform);
        }
      } else {
        resolve(this.defaultPlatform);
      }
    })

  }

  getAuthToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      // if (!this.isTeamsEnvironment) {
      //   reject('Not running inside Teams');
      //   return;
      // }

      microsoftTeams.authentication.getAuthToken({
        successCallback: (result: any) => {
          console.log('teams auth detail res', result)
          localStorage.setItem('token', result);
          const decoded: any = jwtDecode(result);
          localStorage.setItem('teams-username', decoded.name);
          resolve(result);
        },
        failureCallback: (error) => {
          console.log('teams auth detail res filed', error)
          reject(error);
        }
      });
    });
  }


  updateCss() {
    // Get the <head> element
    var head = document.head || document.getElementsByTagName('head')[0];

    // Create a new <style> element
    var style = document.createElement('style');
    // style.type = "stylesheet/css";
    // Add your CSS styles to the <style> element
    style.innerHTML = `
       .row-x {
        height: 99% !important;
       }

       #app_sidenav{
        display: none !important;
       }

       .responsive {
        height: calc(100vh - 66px) !important;
       }

       /* Add more styles as needed */
   `;

    // Append the <style> element to the <head> element
    head.appendChild(style);
    console.log(style)
  }
}
