import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loading = new Subject();
  celebrate = new Subject();
  loadingMessage = new Subject();
  
  

}
