import { Component, OnInit } from '@angular/core';
import { TeamsAuthService } from '../shared/service/teams-auth.service';

@Component({
  selector: 'app-teamsauth',
  templateUrl: './teamsauth.component.html',
  styleUrls: ['./teamsauth.component.scss']
})
export class TeamsauthComponent implements OnInit{
 constructor(private teamsAuthService:TeamsAuthService){

 }

 ngOnInit(): void {
   this.teamsAuthService.getAuthToken();
 }
}
