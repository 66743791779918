<div class="firstTimeComponent">
    <h1 class="heading-section" mat-dialog-title>Important Instructions</h1>
    <div  mat-dialog-content>
            <ul>
                <li>
                    <div class="alert-text">
                        Please be aware that the messages have been generated by an artificial intelligence model. While efforts have been made to provide accurate and relevant information, there is a possibility of errors or inaccuracies. The response you receive should not be considered as professional or expert advice. We strongly recommend cross-checking the information with reliable sources or consulting a human expert when necessary. The purpose of this AI is to assist and provide general information, but it may not always be perfect. Use the information at your own discretion.
                        The AI-generated response will be limited to 1,000 characters to ensure concise and focused answers.
                     </div>
                </li>
                <li>
                    <div class="alert-text">
                        The AI-generated response will be limited to 1,000 characters to ensure concise and focused answers
                    </div>
                </li>
                <li>
                    <div class="alert-text">If you have any inquiries, we kindly encourage you to consult Genpact's Code of Conduct Document and ChatGPT and Use of AI Programs Policy.</div>
                </li>
                <li>
                    <div class="alert-text">Kindly note that your inputs maybe subject to audit, and may be used for refining the accuracy of responses provided by the BOT</div>
                </li>
            </ul>
            <div class="col-md-12 pl-5" style="display: flex; align-items: flex-start;">


                        <input type="checkbox"  [(ngModel)]="isChecked" name="checkbox" id="acknowledge-checkbox" class="form-check-input" style="flex: 0 0 auto; background-color: #073262;padding: 0px!important;"   (change)="acceptTermsCondition($event)">
                <label  for="acknowledge-checkbox" style=" flex: 1;" class="label-acknowledge">
                    <span id="acknowledge-checkbox-label">
                        <strong>I acknowledge that I have read, understand and agree to above instructions.</strong>
                    </span>
                </label>

            </div>
            </div>

    <div class="mt-2 text-right pull-right" mat-dialog-actions>
        <span [ngClass]="{'cursor-not': !isChecked}">
            <button class="btn btn-primary" [disabled]="!isChecked" (click)="submit()">Proceed</button>
        </span>
    </div>
</div>
