import { ToastMessageService } from './../shared/service/toast-message.service';
import { LoaderService } from './../shared/service/loader.service';
import { Component, Input, TemplateRef, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SocService } from '../shared/service/soc.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-soc-report',
  templateUrl: './soc-report.component.html',
  styleUrls: ['./soc-report.component.scss']
})

export class SocReportComponent implements OnInit  {
  @ViewChild('pdfInput') pdfInput!: ElementRef;
  @ViewChild('excelInput') excelInput!: ElementRef;
  chkagree: boolean = false;
  showFirst: boolean = true;
  showSecond: boolean = false;
  showThird: boolean = false;
  modalRef?: BsModalRef;
  startingPageNo: string = '';
  CcstartingPageNo : string = '';
  controlName: string = 'CA';
  reviewCategory: string = 'ms';
  windowRef = window;
  pdfFile: File | any = null;
  excelFile: File | any = null;
  pdfFileName: string = '';
  excelFileName: string = '';
  targetUrl: any;
  newControlNameOption: string = '';
  newReviewCategoryOption: string = '';
  apiHits = 0;
  controlOptions = [
    { value: 'CONTROL DESCRIPTION', label: 'CONTROL DESCRIPTION' },
    { value: 'Control Activity', label: 'Control Activity' },
    { value: 'Control Activity Specified by the Service Organization', label: 'Control Activity Specified by the Service Organization' },
    { value: 'other', label: 'Others' },
  ];
  reviewOptions = [
    { value: 'msft', label: 'Microsoft' },
    { value: 'oci', label: 'OCI' },
    { value: 'omcs', label: 'OMCS' },
    { value: 'other', label: 'Others' },
  ];
userAccessStatus: boolean = false;
gbtn: boolean = true;

  constructor(
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private toastMessage: ToastMessageService,
    private SocService: SocService
    ) {}

   ngOnInit(): void {
    this.startingPageNo = '';
    this.controlName = '';
    this.pdfFileName = '';
    this.excelFileName = '';
    this.CcstartingPageNo = '';
    this.reviewCategory = '';
    this.pdfFile = null;
    this.excelFile = null;
    this.onLoading();
    this.getOHR();
    this.accesscheck();
   }
accesscheck() {
  this.loaderService.loading.next(true);
  this.SocService.useraccesscheck().subscribe((res: any) => {
    if (res?.response?.status === "success") {
     // this.chkagree = true;
     this.loaderService.loading.next(false);
     this.userAccessStatus = true;
      this.toastMessage.showSuccess(res?.response?.message);
    }
    else {
      //this.chkagree = false;
      this.loaderService.loading.next(false);
      this.toastMessage.showError(res?.response?.message);
    }
  },
  (error) => {
    console.log(error);
    this.loaderService.loading.next(false);
    this.toastMessage.showError(error?.error?.response?.message);
  }

  );
}
  toggle() {
    this.showFirst = !this.showFirst;
  }

  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id)
      {
        return ohr_id;
      }
      else {
        return null;
      }
    }
  }


  onPdfFileSelected(event: any): void {
    this.pdfFile = event.target.files[0];
    this.pdfFileName = this.pdfFile.name;
    if(!this.pdfFileName.includes('.pdf')) {
      this.toastMessage.showWarning("Wrong file format. Please upload a pdf file(.pdf)");
      this.resetPdfFile();
    }
  }

  resetPdfFile(): void {
    this.pdfFile = null;
    this.pdfInput.nativeElement.value = '';
  }

  submitPdf(): void {
    if (this.pdfFile) {
      const formData: FormData = new FormData();
      formData.append('pdf_file', this.pdfFile);
      formData.append('user_id', this.getOHR());
      formData.append('start_page', this.startingPageNo);
      if(!this.newReviewCategoryOption) { 
        formData.append('cc_start_page', this.CcstartingPageNo);
      }
      if(this.newControlNameOption) {
        formData.append('column_name', this.newControlNameOption); 
      }
      else {
        formData.append('column_name', this.controlName); 
      }
      if(this.newReviewCategoryOption) {
        formData.append('file_type', this.newReviewCategoryOption);
      }
      else {
        formData.append('file_type', this.reviewCategory);
      }
      this.loaderService.loading.next(true);
      this.toastMessage.showWarning("Please wait while we are processing your request");
      this.SocService.PdfUpload(formData).subscribe((response) => {
        this.loaderService.loading.next(false);
        this.toastMessage.showSuccess("File has been uploaded successfully");
        this.showFirst = false;
        this.showSecond = true;
      },
      (error) => {
        this.loaderService.loading.next(false);
        console.error('API Call failed:', error);
        this.toastMessage.showError("Please check your inputs and try again.");
      }
      );
    }
  }

  onExcelFileSelected(event: any): void {
    this.excelFile = event.target.files[0];
    this.excelFileName = this.excelFile.name;
    if(!this.excelFileName.includes('.xlsx')) {
      this.toastMessage.showWarning("Wrong file format. Please upload an excel file(.xlsx)");
      this.resetExcelFile();
    }
  }

  resetExcelFile(): void {
    this.excelFile = null;
    this.excelInput.nativeElement.value = '';
  }

  submitExcel(): void {
    if (this.excelFile) {
      const formData: FormData = new FormData();
      formData.append('user_id', this.getOHR());
      formData.append('excel_file', this.excelFile);
      formData.append('pdf_name', this.pdfFileName); 
      if(!this.newReviewCategoryOption) { 
        formData.append('cc_start_page', this.CcstartingPageNo);
      }
      if(this.newReviewCategoryOption) {
        formData.append('file_type', this.newReviewCategoryOption);
      }
      else {
        formData.append('file_type', this.reviewCategory);
      }
      this.loaderService.loading.next(true);
      this.toastMessage.showWarning("Please wait while we are processing your request");
      this.SocService.ExcelUpload(formData).subscribe((response) => {
        this.loaderService.loading.next(false);
        this.toastMessage.showSuccess("File has been uploaded successfully");
        this.showSecond = false;
        this.showThird = true;
        console.log(response.message);
      },
      (error) => {
        this.loaderService.loading.next(false);
        console.error('API Call failed:', error);
        this.toastMessage.showError("Please check your inputs and try again.");
      }
      );
    }
  }

  generate() {
    this.loaderService.loading.next(true);
    this.SocService.ExcelDownload().subscribe(async (response: any) => {
      this.apiHits++;
      if (response?.message == 'excel file is not generated yet' && 48 > this.apiHits)
      {
      setTimeout(() => {
        this.generate();
      }, 15000);
    }

   else if(this.apiHits >= 48){
      this.toastMessage.showWarning('Something went wrong. Please check your inputs once again');
      this.apiHits =0;
      setTimeout(() => {
        location.reload(); 
      }, 1000);             
    }
    else {
      this.gbtn = false;
    }
    },
    (error) => {
      this.loaderService.loading.next(false);
      this.gbtn = false;
      // console.error('API Call failed:', error);
      // this.toastMessage.showError("Please check your inputs and try again.");
    });
  }

  downloadFile() {
    this.SocService.ExcelGenerate().subscribe((response: Blob) => {
      const file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); 
      saveAs(file, 'output.xlsx'); 
    this.loaderService.loading.next(false);
    this.toastMessage.showSuccess('Excel file is downloaded succesfully');
  });
}


  onLoading() {
    const modalbtn = document.getElementById('soc_report_policy_model') as HTMLLIElement;
    modalbtn.click();
  }

  // Open model
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
  }

}
