import { Injectable } from '@angular/core';
// @ts-ignore
import  html2pdf from 'html2pdf.js';

@Injectable({
  providedIn: 'root'
})
export class DownloadPdfService {

  constructor() { }
  downloadAsPdf(divId: string, fileName: string) {
    const element = document.getElementById(divId);
    
    if (!element) {
      console.error(`Element with ID ${divId} not found.`);
      return;
    }

    const pdfOptions = {
      margin: 10,
      filename: fileName,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().from(element).set(pdfOptions).outputPdf().save();
    
  }
}
