import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetingNotesService {
  socApi = environment.apiURL+'/konverse_sfdc';
  accesspoint = environment.nodeEndpoint;

  constructor(private http: HttpClient) { }

  useraccesscheck(): Observable<any> {
    //http post request with payload
    return this.http.post(`${this.accesspoint}validate-role`, {
     'process_value':"GKnowledge",
    });

   }

  getAccount() {
    return this.http.get(`${this.socApi}/accounts`);
  }

  getBuyingCenters(payload:any) {
    return this.http.post(`${this.socApi}/buying-centers`,payload);
  }

  getMeetingNotes(payload:any): Observable<any> {
    return this.http.post(`${this.socApi}/meeting-notes`, payload);
  }

  meetingNotesStatus(payload:any): Observable<any> {
    return this.http.post(`${this.socApi}/meeting-notes-status`, payload);
  }

  sendFeedback(payload: any): Observable<any> {
    return this.http.post(`${this.socApi}/feedback`, payload);
  }

  oppurtunityChat(payload: any) {
    // return this.http.post(`${this.socApi}/opportunities`, payload);
    return this.http.post('https://api.playground-uat.genpact.com/konverse_sfdc/opportunities', payload);  
  }

}

