<app-header [access] = "userAccessStatus" [docType]="selectedDropDownValue"></app-header>
<!-- start loader html -->
<div class="internal loader-wrapper" *ngIf="loaderSpinner">
	<div class="loader-spinner">Loading...</div>
</div>
<div class="internal loader-backdrop" *ngIf="loaderSpinner"></div>
<!-- end loader html -->
<ng-container  *ngIf="!showView && !hasPermission">
	<div class="col-12 p-5 text-center" >
		<div class="row  mt-5">
		  <div class="col-12">
			<h5 class="display-12 pl-5 headtext">
			  User doesn't have permission to access Document Summarization.
			</h5>
		  </div>
		</div>
	  </div>
	</ng-container>
<div class="wrapper" *ngIf="showView && hasPermission">
	<div class="row justify-content-center" style="font-family: GuardianSansRegular !important;">
		<div class="col-md-12">
			<p class="text-center mt-1 pt-2 text-danger w-100 font-17">Only pdf, docx, xlsx, xlsb and text file are
				allowed (max-size 16mb)</p>
		</div>

		<div class="row summary-components pt-3 justify-content-center">
			<!-- <div class="col-md-2 col-sm-2 col-12"></div> -->
			<div class="col-md-4 col-sm-4 text-right">
				<span class="font-weight-bold p-2 w-50" style="color: #073262;">Doc Type</span>
				<select class="custom-select" [(ngModel)]="selectedDropDownValue"
					(change)="selectDocType(selectedDropDownValue, 'docType')">
					<option disabled>Select</option>
					<!-- <option value="Document">Document Based</option> -->
					<option *ngFor="let item of docTypeList">{{item}}</option>
					<!-- <option value="Finance">Finance Functional</option> -->
					<!-- <option value="NDA Review">NDA Review</option> -->
				</select>
			</div>
			<div class="col-md-2 col-sm-2 d-flex flex-column">
				<input style="display: none;" type="file" name="file-2[]" id="file-2" class="custom-file-input"
					data-multiple-caption="{count} files selected" multiple (change)="onFileSelected($event)" #fileInput
					accept=".pdf,.docx,.xlsx,.xlsb,.txt">
				<button tooltip="Upload files to translate"
					style="border-radius:0px;background:#ffffff!important;color: #073262!important; width: 100%; "
					(click)="fileInput.click()" type="button" id="yourButton" class="btn btn-primary py-1 btn-block">
					<span class="button-text" style="color: #073262;">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
							class="bi bi-upload" viewBox="0 0 16 16">
							<path
								d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
							<path
								d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
						</svg>
						Choose File
					</span>
				</button>
				<div class="file-info">
					<span class="file">{{fileNames}}</span>
				</div>
			</div>
			<div class="col-12 col-md-3 col-sm-3 align-self-start">
				<button type="button" class="btn btn-block btn-lg btn-primary shadow-lg"
					style="font-size: 13px;height: 37px;border-radius:0px;background-color:#ececec!important;color: #073262!important; width: 50%;"
					(click)="clearKnowledgeBase(true)">Upload files</button>
			</div>
			<div class="clearfix hidden-md-up"></div>
		</div>
		<div class="row summary-components mt-3 justify-content-center">
			<div class="col-md-4 col-sm-4">
				<p class="pt-1 text-right"> <span class="font-weight-bold p-2 spantext" style="color: #073262;">Choose
						what you want genAI to help you with</span> </p>
			</div>
			<div class="col-12 col-md-2 col-sm-2 d-flex">
				<select [disabled]="selectedDropDownValue =='Select' || !fileUploadSuccessfully"
					class="custom-select options" [(ngModel)]="category"
					(change)="selectDocType(selectedDropDownValue, 'category')">
					<option disabled>Select</option>
					<option *ngFor="let category of financialFunctional" value="{{category}}">{{category}}</option>
				</select>
			</div>
			<div class="col-12 col-md-3 col-sm-3">
				<button
					[disabled]="selectedDropDownValue =='Document' || selectedDropDownValue =='NDA Review' ||  selectedDropDownValue =='Select' || category == 'Select' || myFiles.length == 0"
					type="button" class="btn btn-block btn-lg btn-primary shadow-lg"
					style="font-size: 13px;height: 37px; border-radius: 0px; width: 50%;"
					(click)="categorySubmit(category)">Submit</button>
			</div>
		</div>
		<div class=" mt-3">
			<div class="row-x justify-content-center">
				<div class="col-sm-11 col-md-11">
					<div class="card card-default">
						<div class="card-header genbgblue">
							<h5 class="card-title text-white">Document Summary <small><img src="images/del.png" alt=""
										style="height: 19px; margin-top: -7px;"></small></h5>
							<div class="card-tools">
								<button type="button" class=" btn-tool" data-card-widget="collapse"
									(click)="ExportToExcel()">
									Export <i class="fa fa-share-square-o" aria-hidden="true"></i>
								</button>
							</div>
						</div>
						<!-- /.card-header -->
						<div class="content shadow border" style="display: block;">
							<div class="row">
								<div role="tabpanel" class="active tab-pane toolTab1 w-100" id="toolTab1">
									<div class="row">
										<div class="summarycontent p-3" id="summary-content-scroll"
											[ngClass]="{'no-chat':!chatQns.length && !(promptAns && promptAns[0] && promptAns[0].type == 'finance')}">
											<p class="text-blue"
												*ngIf="!chatQns.length && !(promptAns && promptAns[0] && promptAns[0].type == 'finance')">
												No summary</p>
											<table id="excel-table">
												<table class="table table-responsive table-bordered promtTable"
													*ngIf="promptAns && promptAns[0] && promptAns[0].type != 'document'">
													<thead>
														<tr>
															<th *ngFor="let header of tableHeaders">{{ header }}</th>
														</tr>
													</thead>
													<tbody>
														<tr *ngFor="let item of promptAns[0].value">
															<td *ngFor="let header of tableHeaders">{{ item[header] }}
															</td>
														</tr>
													</tbody>
												</table>
												<tr *ngFor="let qns of chatQns;let i =index">
													<td colspan="2" class="table-ques">
														{{qns}}</td>
													<br>
													<td class="table-ans-show" [innerHTML]="chatAns[i]"
														*ngIf="!this.firstTimeDataInFinanace">
													</td>
													<br>
												</tr>
											</table>
										</div>
										<div class="user-input">
											<div class="box input-box">
												<textarea [(ngModel)]="value " class="message-input"
													placeholder="Ask a question" rows="1" #textArea
													(keydown.enter)="submitQuery()"></textarea>
												<div id="send-button" (click)="submitQuery()">
													<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
														<path fill="#006ae3" d="M2,21L23,12L2,3V10L17,12L2,14V21Z">
														</path>
													</svg>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pb-3">
						<div class="content shadow">
							<img style="height: 5px; width:100%;" src="../../../assets/images/gbar.png" alt="icon"
								class="mb-3 w-48" />
							<div class="item1 px-3">
								<div class="">
									<div style="display:flex; flex-direction: row; gap:4px">
										<img style="height: 12px;position: relative;top: 4px;" class="arrow-icon"
											src="assets/images/arrow.png" alt="" />

										<p>Please consult Genpact’s
											<a class="text-skyblue cursor-pointer" style="text-decoration: underline; "
												href="https://genpactonline.sharepoint.com/sites/Legal/Compliance/Shared%20Documents/Integrity/Code%20of%20Conduct.pdf"
												target="_blank"> Code of Conduct document</a>
											and <a class="text-skyblue cursor-pointer"
												style="text-decoration: underline; "
												href="https://genpactonline.sharepoint.com/sites/Legal/Compliance/Shared%20Documents/PolicyCentralold/Use%20of%20Generative%20AI%20Programs%20Policy.pdf"
												target="_blank">Use of Generative AI Programs Policy</a> before using
											Generative AI Playground or
											any other gen AI platform.
										</p>
									</div>
								</div>

								<div class="">
									<div style="display:flex; flex-direction: row; gap:4px">
										<img style="height: 12px;position: relative;top: 4px;" class="arrow-icon"
											src="assets/images/arrow.png" alt="" />
										<p>Learn more about <a class="text-skyblue cursor-pointer"
												style="text-decoration: underline;"
												href="https://genpactonline.sharepoint.com/sites/IT/Documents/Policies/Open%20AI%20policy/Azure%20openai%20IT%20enablement-%20v3.pdf"
												target="_blank">Azure OpenAI IT enablement</a>.</p>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>