<app-sidenav></app-sidenav>
<div class="container-fluid text-center mt-5">
  <div class="row">
    <div class="col-12">
      <h1 class="display-4 text-primary mb-4" style="color: #01152B!important;">Welcome to Konverse</h1>
      <p class="lead">Please click below to access links:</p>
      <!-- <div class="d-flex "  aria-label="Navigation Buttons">
        <a class="btn btn-primary" [routerLink]="['/soc-report']" routerLinkActive="active">Soc Report</a>
        <a class="btn btn-primary" [routerLink]="['/bgc']" routerLinkActive="active">BGC Validation</a>
      </div> -->
<div class="socbtn">
      <button type="button" [routerLink]="['/soc-report']" routerLinkActive="active" class="btn btn-primary">SOC Report</button>

      <button type="button" style="margin-left: 10px;" [routerLink]="['/bgc']" routerLinkActive="active"class="btn btn-primary">BGC Validation</button>

      <!-- <button type="button" style="margin-left: 10px;"  class="btn btn-primary">
        <a href="https://genpactdevelop.service-now.com/ai" style="margin-left: 10px;" class="btn btn-primary" target="_blank">Audit Compliance</a>
      </button> -->

      <button type="button" style="margin-left: 10px;" [routerLink]="['/meeting-assist']" routerLinkActive="active"  class="btn btn-primary">Meeting Assist</button>
    
    </div>
    </div>
  </div>
</div>
<app-app-footer></app-app-footer>

