import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TeamsAuthService } from '../service/teams-auth.service';



@Injectable({
  providedIn: 'root'
})
export class HomeResolver implements Resolve<any> {

  constructor(private teamsAuthService: TeamsAuthService) { }

  resolve() {
    return new Promise((resolve, reject) => {

    // setTimeout(() => {
      this.teamsAuthService.getTeamsStatus().then((res: any) => {
        console.log('reslover service', res);
        // this.dynamicRoutesService.addDynamicRoutes(res);
        resolve(res)
      })
    // }, 3000);

    })
  }
}
