import { Component,OnInit } from '@angular/core';
import { OktaAuthService } from '../shared/okta/okta.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent  implements OnInit{
  isAuthenticated: boolean = false;
  constructor(public oktaAuth: OktaAuthService,private router: Router) {}

  ngOnInit(): void {
    //if(this.isAuthenticated== false){
     // console.log('worked?')
       this.userLogin()
   //  this.oktaAuth.$isAuthenticated.subscribe(val => this.isAuthenticated = val);

    //}
  }
  userLogin(){
    this.oktaAuth.login('/')
//    this.router.navigate(['/support']);
  }
}
