import { Component } from '@angular/core';

@Component({
  selector: 'app-home-konverse',
  templateUrl: './home-konverse.component.html',
  styleUrls: ['./home-konverse.component.scss']
})
export class HomeKonverseComponent {

}
