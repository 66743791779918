<nav *ngIf="title!='MeetingAssist'"  class="navbar navbar-expand-lg navbar-dark bg-dark shadow" id="app_sidenav"
  style=" background-color:#02132d!important;     background-position-y: center;">
  <a class="navbar-brand ml-md-3" href="javascript:;">

    <img src="assets/images/glogo.png" alt="Responsive Image" class="img-fluid" [routerLink]="['/Home']">

    <h3 class="display-12  mt-md-2" style="color:#00aecf;">{{title}}</h3>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav  mr-auto mt-md-5 mr-md-5">

      <li *ngIf="title !='MeetingAssist'" class="nav-item">
        <a class="nav-link" [routerLink]="['/Home']" routerLinkActive="active">Home <span
            class="sr-only">(current)</span></a>
      </li>

      <!-- <li class="nav-item ">
        <a class="nav-link" [routerLink]="['/leaderboard']" routerLinkActive="active">Leaderboard <span
            class="sr-only">(current)</span></a>
      </li> -->


      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="javascript:;" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Konverse
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" [routerLink]="['/soc-report']" routerLinkActive="active">SOC Report</a>
          <div class="dropdown-divider"></div>
          <a
          class="dropdown-item" [routerLink]="['/bgc']" routerLinkActive="active">BGC Validation</a>
          <div class="dropdown-divider"></div>
          <!-- <a href="https://genpactdevelop.service-now.com/ai"  class="dropdown-item" target="_blank"  >Audit Compliance</a> -->

          <!-- <a class="dropdown-item" [routerLink]="['/byod']" routerLinkActive="active">Bring your own Files</a> -->
          <!-- <div class="dropdown-divider"></div> -->
          <a class="dropdown-item" [routerLink]="['/meeting-assist']" routerLinkActive="active">Meeting Assist</a>

          <!-- <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/document-summary']" routerLinkActive="active">Document Summary</a> -->
        </div>
      </li>
      <!-- <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Resources
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">



          <a class="dropdown-item" target="_blank"
            href="https://prodapps.genpact.com/RSAarcher/default.aspx?requestUrl=../Foundation/workspaceDashboard.aspx?workspaceId%3d4">
            ChatGPT Policy</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" target="_blank"
            href="https://genpactonline.sharepoint.com/sites/IT/Documents/Policies/Open%20AI%20policy/Azure%20openai%20IT%20enablement-%20v3.pdf"
            >ChatGPT Enablement</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" target="_blank"
            href="https://genpactindprod.service-now.com/sp?id=sc_cat_item&sys_id=5107df6b47c7251823a95ffbd36d43a9">
            HelpMate</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" target="_blank" href="https://genome.genpact.com/my-plan">Genome</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/success-stories']" routerLinkActive="active">Success Stories</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="downloadFile()">Zoom background</a>

        </div>
      </li> -->

    </ul>
    <form class="form-inline my-2 my-lg-0">

      <button (click)="logout()" class="btn btn-outline-success my-2 my-sm-0 desktop-logout" type="submit" style="border-radius:0px; border-color: #00aecf;  color:#00aecf">Logout <img
          src="assets/images/logouticon.png" alt="icon" /></button>
    </form>

  </div>
  <!-- <div><a class="navbar-brand  mr-0 mr-md-2" href="#">gen AI</a></div> -->

</nav>

<nav *ngIf="title==='MeetingAssist'" class="navbar navbar-expand-lg navbar-dark bg-dark shadow"  id="app_sidenav"
  style=" background-color:#02132d!important;     background-position-y: center; max-height: 60px;">
  <a class="navbar-brand ml-md-3" href="javascript:;">

    <img src="assets/images/glogo.png" alt="Responsive Image" class="img-fluid" [routerLink]="['/Home']">

    <h3 *ngIf="title !='MeetingAssist'" class="display-12  mt-md-2 " style="color:#00aecf;">{{title}}</h3>
  </a>
  <div *ngIf="title==='MeetingAssist'" class="navbar-collapse">
    <h3 class="display-12  mt-md-2" style="color:#00aecf; padding-right: 150px;">{{title}}<sup class="beta-tag">&nbsp;BETA LAUNCH&nbsp;</sup></h3>
  </div>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <form class="form-inline my-2 my-lg-0">

      <button (click)="logout()" class="btn btn-outline-success my-2 my-sm-0 desktop-logout" type="submit" style="border-radius:0px; border-color: #00aecf;  color:#00aecf">Logout <img
          src="assets/images/logouticon.png" alt="icon" /></button>
    </form>

  </div>
  <!-- <div><a class="navbar-brand  mr-0 mr-md-2" href="#">gen AI</a></div> -->

</nav>
