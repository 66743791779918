import { Component } from '@angular/core';

@Component({
  selector: 'app-platform-check',
  templateUrl: './platform-check.component.html',
  styleUrls: ['./platform-check.component.scss']
})
export class PlatformCheckComponent {

}
