
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
declare var window:any;
@Component({
  selector: 'app-first-time-modal',
  templateUrl: './first-time-modal.component.html',
  styleUrls: ['./first-time-modal.component.css']
})
export class FirstTimeModalComponent {
acceptTerms = false;
isChecked = false;
formModal:any;
constructor(public dialogRef: MatDialogRef<FirstTimeModalComponent>){
  dialogRef.disableClose = true; 
  localStorage 
}
ngOnInit(){
 this.formModal = new window.bootstrap.Modal(
    document.getElementById("exampleModal")
  )
}
acceptTermsCondition(event: any){
  if (event.target.checked) {
    this.isChecked = true;
  } else {
    this.isChecked = false
  }
 }
 submit(){
   if(this.isChecked){
      this.dialogRef.close();
      localStorage.setItem('showTerms&ConditionPopup', 'true')
   }
 }

}
