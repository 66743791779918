<app-sidenav></app-sidenav>
<div style="padding-right: 11px; padding-top: 5px">
<div class="row">
  <div class="col-12 text-center">
      <h3 style="margin-top: 15px; font-family: Georgia; color: #073262; ">
          <b>AI Audit Assistant</b>
      </h3>
      <div style="text-align: right;  margin-top: -50px;">
      <button type="button" tooltip="Instructions" class="btn btn-primary" style="background-color:#ffffff!important" (click)="openModal(soc_report_policy_model)" style="font-family: georgia;">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
        </svg>
      </button>
    </div>
    </div>
</div>
<div class="container-fluid" *ngIf="userAccessStatus">
  <div class="row px-sm-2 px-md-2 justify-content-center align-self-start py-2" style="margin-bottom: 80px;">
    <div class="col-12 col-md-7 col-sm-10 col-lg-7 px-3 d-md-grid">
      <p style="font-family: Georgia; font-size: 25px;">Step 1</p>
      <div class="content shadow border" [class.disabled]="!showFirst">
        <div class="">
          <br>
          <div class="row justify-content-center px-4">
            <div class="col-md-6">            
            <div class="input">SOC-Report Vendor</div>
              <select class="form-select" title="Load an Example" name="reviewCategory" [(ngModel)]="reviewCategory"
                style="border-radius: 0; width: 90%; margin-top: 10px;">
                <option *ngFor="let option of reviewOptions" [value]="option.value">{{ option.label }}</option>
              </select>
              
              <input class="form-control" *ngIf="reviewCategory === 'other'" type="text" [(ngModel)]="newReviewCategoryOption" placeholder="Enter a New Vendor" style="margin-top: 10px; width: 90%; border-radius: 0;" (input)="windowRef.localStorage.setItem('file_type', reviewCategory)">
              </div>

            <div class="col-md-6" [class.disabled]="reviewCategory === 'other'">
              <div class="input" >Starting Page no. of Complementary Controls</div>
              <input type="text" style="width: 90%; border-radius: 0; margin-top: 10px;" class="form-control"
                placeholder="Enter page no." [(ngModel)]="CcstartingPageNo"
                (input)="windowRef.localStorage.setItem('cc_start_page', CcstartingPageNo)"
                >
            </div>
          </div>
          <br>
          <div class="row px-4">
            <div class="col-md-6">
              <div class="input">Control Name</div>
              <select class="form-select" title="Load an Example" name="controlName" [(ngModel)]="controlName"
                style="border-radius: 0; width: 90%; margin-top: 10px;">
                <option *ngFor="let option of controlOptions" [value]="option.value">{{ option.label }}</option>
              </select>

              <!-- Input field for entering a new option -->
              <input class="form-control" *ngIf="controlName === 'other'" type="text" [(ngModel)]="newControlNameOption"
                placeholder="Enter a New Control Name" style="margin-top: 10px; width: 90%; border-radius: 0;"
                (input)="windowRef.localStorage.setItem('column_name', controlName)">
            </div>

            <div class="col-md-6">
              <div class="input">Starting Page no. of Controls</div>
              <input type="text" style="width: 90%; border-radius: 0; margin-top: 10px;" class="form-control"
                placeholder="Enter page no." [(ngModel)]="startingPageNo"
                (input)="windowRef.localStorage.setItem('start_page', startingPageNo)">
            </div>
          </div>
          <div class="row">
            <div class="upload-section">
              <!-- Upload Box -->
              <div class="cont">
                <div class="upload-cont">
                  <div class="upload-box">
                    <label for="pdfFile">
                      <h5 style="color: #073262;"><br>Choose your SOC Report</h5>
                      <p style="text-align: center; color: #999999;">Only .pdf files are allowed</p>
                      <input type="file" #pdfInput id="pdfFile" (change)="onPdfFileSelected($event)" accept=".pdf">
                    </label>
                  </div>
                  <br>
                  <div *ngIf="pdfFile" class="file-info">
                    <p style="color: #00aecf; font-size: 12px; margin-top: -10px;">PDF File: {{ pdfFileName }} <span
                        (click)="resetPdfFile()" class="reset-icon">&#10006;</span></p>
                  </div>
                </div>
                <button (click)="submitPdf()" title="Upload" class="submit-button2" [disabled]="!pdfFile"><i
                    class="fa fa-upload" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-5 col-sm-10 col-lg-5 px-3 d-md-grid">
      <div class="row align-self-start py-3 py-md-0">
        <p style="font-family: Georgia; font-size: 25px;">Step 2</p>
        <div [class.disabled] ="!showSecond">
          <div class="content shadow border">
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <div class="row">
                    <div class="upload-section">
                      <!-- Upload Box -->
                      <div class="cont">
                        <div class="upload-cont">
                          <div class="upload-box1">
                            <label for="excelFile">
                              <h5 style="color: #073262;"><br>Choose your Template sheet</h5>
                              <p style="text-align: center; color: #999999;">Only .xlsx files are allowed</p>
                              <input type="file" #excelInput id="excelFile" (change)="onExcelFileSelected($event)"
                                accept=".xlsx,.XLXS,.csv,.CSV,.xls,.XLS">
                            </label>
                          </div><br>
                          <div *ngIf="excelFile" class="file-info">
                            <p style="color: #00aecf; font-size: 12px;">Excel File: {{excelFile.name}} <span
                                (click)="resetExcelFile()" class="reset-icon">&#10006;</span></p>
                          </div>
                        </div>
                        <br>
                        <button (click)="submitExcel()" title="Upload" class="submit-button2 view-match-button"
                          [disabled]="!excelFile"><i class="fa fa-upload" aria-hidden="true"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-self-end  py-3 py-md-0">
        <p style="font-family: Georgia; font-size: 25px; margin-top:20px">Step 3</p>
        <div [class.disabled]="!showThird">
          <div class="content shadow border">
            <div class="button-container">
              <div>
                <button class="btn" title="Generate" (click)="generate()" [disabled]="!gbtn">Generate xlsx
                </button>
              </div>

              <div>
                <button class="downloadbtn" title="Download" (click)="downloadFile()" [disabled]="gbtn">Download
                  <i class="fa fa-download" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<app-app-footer style="padding-top: 50px !important;"></app-app-footer>

<!-- policy & condition toggle -->
<button type="button" class="btn btn-primary d-none" id="soc_report_policy_model" (click)="openModal(soc_report_policy_model)">
</button>

<!-- policy & condition block -->
<ng-template #soc_report_policy_model>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 fw-bold text-primary">Disclaimer:
      </div>
      <div class="row">
        <div class="col-12" style="font-size: 12px;">
        <ol style="list-style: decimal;">
        <li>
          The responses generated by GenAI, are based on patterns and data it has been trained on. While efforts have been made to provide accurate information, there is a possibility of errors.
        </li>
        <li>
          The responses should not be considered as professional advice. Cross-check the information with reliable sources or consult a human expert.
        </li>
        <li>
         Use the information at your own discretion and avoid uploading unnecessary or sensitive files for privacy and security reasons.
        </li>
        </ol></div>
      </div>
    <div *ngIf="userAccessStatus" class="row">
      <div class="col-12 fw-bold text-primary">Instructions:</div>
    </div>
    <div class="row">
      <div class="col-12" style="font-size: 12px;">
        <ol style="list-style:decimal;">
          <li>
            Please make sure that ‘Service Organization IT General Controls’ / column ‘F’ contains the pdf name.
          </li>
          <li>
            Please make sure that ‘Control Description’ / column ‘D’ of ITGC mapping contains the control description.
          </li>
          <li>
            Please provide the correct page numbers for the start of the control objective and the start of the complementary control in order to successfully upload the PDF document.
          </li>
          <li>
            The predicted values will be in the column 'K' of the worksheet of 'ITGC mapping'.
          </li>
          <li>
            For the Cover Tab the GenAI responses will be saved in column 'D'.
          </li>
          <li>
            The sub service providers and CUEC sheet will be automatically included in the Excel file.
          </li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-12 ml-4">
        <input id="soc_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
        <label class="form-check-label text-decoration-underline fw-bold text-primary" style="margin-left: 20px;margin-top: -5px;"
          for="soc_check_label">
          I acknowledge that I have read, understand and agree to above instructions.
        </label>
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn button-genpact text-white center cursor-pointer" [disabled]="!chkagree"
      (click)="modalRef?.hide()">
      Proceed
    </button>
  </div>
  </div>
</ng-template>
<!-- <app-app-footer></app-app-footer> -->
</div>
<!-- <app-app-footer></app-app-footer> -->
<div class="col-12 p-5 text-center" *ngIf="!userAccessStatus">
  <div class="row  mt-5">
    <div class="col-12">
      <h5 class="display-12 pl-5 headtext">
        User doesn't have permission to access SOC.
      </h5>

    </div>
  </div>
</div>


