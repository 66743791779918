<app-sidenav></app-sidenav>
<div class="container-fluid text-center ">
  <div class="row">
    <div class="col-12">
      <h1 class="display-4 text-primary " style="color: #01152B!important;">Welcome to Konverse</h1>
      <p class="lead">Please click below to access links:</p>
<div *ngIf="cardsData.length>0" class="landing-page">
  <div class="card-container">
    <div class="row" *ngFor="let row of paginatedData">
      <div class="col" *ngFor="let card of row">
        <div class="card" (click)="redirectToLink(card)">
          <h3 style="white-space: pre-line;">{{ card.title }}</h3>
          <!-- <p>{{ card.description }}</p> -->
        </div>
      </div>
    </div>
  </div>

  <div class="pagination">
    <button (click)="onPreviousPage()" [disabled]="currentPage === 1">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
      </svg>
    </button>
    <button *ngFor="let _ of totalPagesArray; let i = index"
            [class.active]="currentPage === i + 1"
            (click)="onPageChange(i + 1)">
      {{ i + 1 }}
    </button>
    <button (click)="onNextPage()" [disabled]="currentPage === totalPages">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
      </svg>
    </button>
  </div>
</div>
</div>
</div>
</div><br><br><br>
<app-app-footer></app-app-footer>
