<div class="p-5 text-center">
    <h3 class="mt-4">
        Sorry, you are not authorized to Meeting Assist.
        <br>
        <br>
        
        <b class="px-3 text-primary" routerLink="/meeting-assist">HOME</b>
        <br>
        <br>
        
        Thank you for your understanding.
      </h3>
</div>