import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocService {
  socApi = environment.apiURL ;
  accesspoint = environment.nodeEndpoint;




  constructor(private http: HttpClient) { }
  useraccesscheck(): Observable<any> {
    //http post request with payload
    return this.http.post(`${this.accesspoint}validate-role`, {
     'process_value':"SOC",
    });

   }

  PdfUpload(formData: FormData): Observable<any> {
    return this.http.post(`${this.socApi}/soc/upload-soc-files`, formData);
  }

  ExcelUpload(formData: FormData): Observable<any> {
    return this.http.post(`${this.socApi}/soc/excel-upload`, formData);
  }

  ExcelGenerate() {
    return this.http.get(`${this.socApi}/soc/excel-download`, { responseType: 'blob'});
  }

  ExcelDownload() {
    return this.http.get(`${this.socApi}/soc/excel-download`);
  }
}
