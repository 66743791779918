import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastMessageService } from './shared/service/toast-message.service';
import { Location } from '@angular/common';
import { TeamsAuthService } from './shared/service/teams-auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
     private toastMessage: ToastMessageService,
     private location: Location,
     private teamsService: TeamsAuthService
     ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token: any = localStorage.getItem('token');

    // add bearer before specific token  starts
    let bearerArray = [
    'recent_bing','recent_response','sentiments-analysis','add-doc','del-doc-list','doc-list',
    'upload-soc-files','excel-upload', 'excel-download','bgc-upload','init','upload','list','manage','export','process',
    'accounts', 'meeting-notes', 'meeting-notes-status', 'feedback', 'qa', 'upload', 'status','clear_knowledge_base','getfiles',
     'download','uploadprompt', 'queryVS-status', 'opportunities', 'doctypeLOV','adminViewLOV','promptCatagoryLOV'];
    // Get the API URL endpoint
    let apiEndpoint = request.url.split('/');
    let finalUrl = apiEndpoint[apiEndpoint?.length - 1].split('?')[0];


    if (bearerArray.includes(finalUrl)) {
      token = 'Bearer ' + token;
    }
    // add bearer before specific token  end

    request = request.clone({
      headers: request.headers.set('authorization', token)
    })

    let authPlatform = this.teamsService.currentPlatform.value;
    if (authPlatform === 'teams_tab') {
      request = request.clone({
        headers: request.headers.set('Auth-platform', authPlatform)
      })
    }

    return next.handle(request).pipe(
      catchError(errorData => {
        // console.log(errorData)
        if ( errorData.status === 409 ||
           errorData.status === 403 || errorData.status === 400
          || errorData.status === 422) {

          this.router.navigate(['/unauthorized']);
            return throwError(errorData)

        }else if (errorData.status === 401){
          console.log('platform',authPlatform)
          if(authPlatform === "web"){
            const currentUrl = this.location.path();
            localStorage.setItem('p_redirect', currentUrl)
            this.router.navigate(['/']);
          }else{
            console.log('unauthorized')
            this.router.navigate(['invalidAuth']);
          }
          
        }
         else if (errorData.status === 422) {

        } else if (errorData.status === 404) {

        }
        else if (errorData.status === 429) {
          console.log('hello', errorData?.error?.message)
          this.toastMessage.showError(errorData?.error?.message);
        }
        return throwError(errorData)
      })

    )
  }
}


export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
}
