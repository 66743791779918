<div class="">
    <div class="item">
        <form data-validation="true" enctype="multipart/form-data">
            <div class="item-inner">
                <div class="item-content px-md-5 py-md-3">
                    <div class="image-upload ">
                        <label class=""label1 style="cursor: pointer;" for="file_uploadx">
                            <img src="" alt="" class="uploaded-image">
                            <div class="h-100">
                                <div class="dplay-tbl">
                                    <div class="dplay-tbl-cell p-3">
                                        <h5><b>Choose Your File to Upload</b></h5>
                                        <p>{{placeholder}}</p>
                                    </div>
                                </div>
                            </div>
                            <!--upload-content-->
                            <input *ngIf="toggle" [accept]="inputAccept" data-required="image" type="file" name="image_name"
                                id="file_uploadx" class="image-input" data-traget-resolution="image_resolution" value=""
                                (change)="upload($event)" (drag)="upload($event)">
                        </label>
                    </div>
                    <br>
                </div>
            </div>
        </form>
    </div>

</div>