
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn() {
    const token = localStorage.getItem('okta-token-storage') as any;
    const payload = JSON.parse(token); // decode payload of token
    if (payload?.accessToken === undefined) {
      return false;
    }
    const expiresAt = payload?.accessToken?.expiresAt;
    if (expiresAt === undefined) {
      return false;
    }
    const expdate = new Date((expiresAt) * 1000);
    if (expdate > new Date()) {
      return true;
    } else {
      return false;
    }
  }
}
