import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor(private toastr: ToastrService){

  }

  showWarning(message:string) {
    this.toastr.warning(message);
  }

  showSuccess(message:string) {
    this.toastr.success(message, 'Success!');
  }
   showmessage(message:string){
    this.toastr.info(message)
   }
   Showhome(message:string) {
    this.toastr.success(message);
   }
  showError(message:string) {
    this.toastr.error(message);
  }

  clearAll() {
    this.toastr.clear();
  }

}
