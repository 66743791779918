
<section class="content">
    <div class="container-fluid responsive p-0" >
        <div class="container">
            <div class="row py-3 px-md-5">
                <div class="col-12 px-md-5" >
<div>
                    <div class="block-dropdown d-grid gap-2 d-sm-flex p-3 justify-content-center" >
                        <div class="" style="font-family: georgia;">
                            <div class="d-none d-sm-block"> &nbsp;</div>
                            <div class="feild-option mt-md-2"> Select: </div>
                        </div>


                        <div class="col-item  ">
                            <div class="form-title"> Region</div>
                            <ng-multiselect-dropdown [placeholder]="'Region'" [settings]="dropdownSettings"
                                [data]="options" [(ngModel)]="selectedOption" [ngClass]="{'disabled':bgcDetailData?.processId}"
                                (ngModelChange)="onDropdownChange()">
                            </ng-multiselect-dropdown>
                        </div>

                        <!-- Testing Type Input (stacked on small and medium screens, aligned horizontally on large screens) -->
                        <div class="col-item ">
                            <div class="form-title"> Testing type </div>
                            <!-- <input type="text" [(ngModel)]="inputtask" style="border-radius: 0px;" class="form-control"
                            [ngClass]="{'disabled':bgcDetailData?.processId}"
                                placeholder="Testing type" (input)="windowRef.localStorage.setItem('task', inputtask)"> -->

                                <ng-multiselect-dropdown [placeholder]="'Testing type'" [settings]="dropdownSettings"
                                [data]="testingOption" [(ngModel)]="inputtask" [ngClass]="{'disabled':bgcDetailData?.processId}"
                                (ngModelChange)="onDropdownChange()">
                            </ng-multiselect-dropdown>
                        </div>

                        <!-- Task Name Input (stacked on small and medium screens, aligned horizontally on large screens) -->
                        <div class="col-item ">
                            <div class="form-title"> Task Name </div>
                            <!-- <input type="text" [(ngModel)]="inputname" style="border-radius: 0px;" class="form-control"
                            [ngClass]="{'disabled':bgcDetailData?.processId}"
                                placeholder="Task name" (input)="windowRef.localStorage.setItem('name', inputname)"> -->

                                <ng-multiselect-dropdown [placeholder]="'Task name'" [settings]="dropdownSettings"
                                [data]="taskNameOption" [(ngModel)]="inputname" [ngClass]="{'disabled':bgcDetailData?.processId}"
                                (ngModelChange)="onDropdownChange()">
                            </ng-multiselect-dropdown>
                        </div>

                        <div class="text-center "  >
                            <div class=" form-title d-none d-sm-block"> &nbsp;</div>

                            <button [disabled]="isButtonDisabled" style="border:none;"
                            tooltip="Click before you upload to initiate the process" type="button" id="yourButton"
                            class="btn btn-primary px-2 py-1 btn-block" (click)="bgcintiate()"> <svg style="width: 18px; height: 24px"
                                viewBox="0 0 24 24">
                                <path fill="#ffffff" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
                            </svg></button>

                        </div>
                    </div>
                    </div>
                    <!--  -->
                </div>
            </div>

            <app-bgc-form [bgcData]="bgcDetailData"></app-bgc-form>



        </div>
    </div>
</section>
