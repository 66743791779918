<app-header></app-header>
<div class="content-wrapper">

    <!-- Main content -->
    <section class="content">


<div class="container-fluid responsive">
    <router-outlet></router-outlet>

</div>
<footer>
    <div class="row">
        <div class="col-md-12 footer-pading">
            <div class="">
                <div class="content">
                    <div class="item1">
                        <span><img title="image " src="../../../../assets/images/arrow.png" class="arrow-icon"></span>
                        <p>Please consult Genpact's
                            <a href="https://genpactonline.sharepoint.com/sites/Legal/Compliance/Shared%20Documents/Integrity/Code%20of%20Conduct.pdf"
                                target="_blank"> Code of Conduct document </a> and
                            <a href="https://genpactonline.sharepoint.com/sites/Legal/Compliance/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FLegal%2FCompliance%2FShared%20Documents%2FPolicyCentral%2FUse%20of%20Generative%20AI%20Programs%20Policy%2Epdf&amp;parent=%2Fsites%2FLegal%2FCompliance%2FShared%20Documents%2FPolicyCentral"
                                target="_blank" class="text-skyblue cursor-pointer"
                                style="text-decoration: underline;">Use of Generative AI Programs Policy</a> before
                            using <br>
                            Generative AI Playground or any other gen AI platform. </p>
                    </div>
                    <div class="item2">
                        <span><img title="learn" src="../../../../assets/images/arrow.png" class="arrow-icon"></span>
                        <p>Learn more about <a
                                href="https://genpactonline.sharepoint.com/sites/IT/Documents/Policies/Open%20AI%20policy/Azure%20openai%20IT%20enablement-%20v3.pdf"
                                target="_blank">Azure OpenAI IT enablement. </a></p>
                    </div>
                </div>
                <div class="content2">
                    <img title="gbar" src="../../../../assets/images/gbar.png" class="gbar">
                </div>
            </div>
        </div>
    </div>
</footer>
</section>

<!-- /.content -->
</div>
