
import { Injectable } from '@angular/core';
import * as docx from 'html-docx-js-typescript';

@Injectable({
    providedIn: 'root',
})
export class DocxService {
    async generateDocx(htmlContent: any, fileName: string) {
        const blobPromise: Promise<any> = docx.asBlob(htmlContent);

        blobPromise.then((content: any) => {
            const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName + '.docx';
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
        });
    }
}
