import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Location } from '@angular/common';
import { TeamsAuthService } from './shared/service/teams-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router,    private location: Location,private teamsAuthService:TeamsAuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if(this.teamsAuthService.currentPlatform.value === 'teams_tab'){
      if(!localStorage.getItem('token')){
        console.log("Not token found in Teams App")
        this.router.navigate(['invalidAuth']);
        return false;
      }
      
    return true;
    }else{
      if (!this.authService.isLoggedIn()) 
      {
        const currentUrl = this.location.path();
        localStorage.setItem('p_redirect', currentUrl);
        console.log('currentUrl',currentUrl)
  
          // alert('Session has been Expired, Please Login again');
          this.router.navigate(['/']);
          return false;
      }
      
    return true;
    }
  
    // return true;
  }
}
