export const environment = {
  production: false,
  apiURL: "https://api.playground-dev.genpact.com", // End point of translate file in python
  bgcURL: "https://gapiw.playground-dev.genpact.com/bgcheck", // End point of BGC in python
  languageApi: "https://api.cognitive.microsofttranslator.com/languages?api-version=3.0&scope=translation",

  nodeEndpoint: 'https://api.playground-dev.genpact.com/nodejs/',
  // nodeEndpoint : 'http://localhost:3000/nodejs/',

  oidc: {
    clientId: '0oa6akyxerb5CqQGc0x7',
    issuer: 'https://genpact.oktapreview.com/oauth2/default',
    redirectUri: 'https://intranet.konverse-dev.genpact.com/home',
    // redirectUri: 'http://localhost:4200/home',
    scopes: ['openid', 'profile', 'email'],
  },
  resourceServer: {
    messagesUrl: 'https://playground-dev.genpact.com/api/messages',
  },

  //okta
  // LOGOUT_REDIRECT_URI: 'http://localhost:4200/logout',
  LOGOUT_REDIRECT_URI: 'https://intranet.konverse-dev.genpact.com/logout',
};





