<div class="col-12 genpact-color ">
  <div class="row py-3">
    <div class="col-6" style="border: 1px sold red;">
      <button class="navbar-toggler hamburger-button" type="button" data-toggle="collapse" aria-expanded="false"
        aria-label="Toggle navigation" style="z-index: 2">
        <div class="animated-icon">
          <span></span><span></span><span></span>
        </div>
      </button>
      <img style="background-size: 200% 200%; margin-left: 40px" height="50px" src="assets/genpact.svg" />
    </div>


  </div>

  <!-- Collapse button -->

  <!-- Navbar brand -->
</div>

<!-- <div class="row">
  <div class="mt-1" style="margin-left:24px;"><p class=" fw-bold txt-genpact" style="font-size: 24px;">Welcome {{username}} ! <span class="wave">👋</span></p></div>
</div> -->


<div class="container-fluid responsive">
  <div class="row p7-5" style="text-align: center;">
    <div class="fw-bold col-12 my-5">

      <h3>
        You have been successfully logged out.

      </h3>
    </div>

    <div class="col-12">
      <button id="regeneratebtn" type="button" class="btn button-genpact text-white w-120" (click)="login()">
        Login
      </button>
    </div>



  </div>
