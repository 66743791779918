import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SopService } from '../shared/service/sop.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-gchat',
  templateUrl: './gchat.component.html',
  styleUrls: ['./gchat.component.scss']
})
export class GchatComponent implements OnInit, OnDestroy {


  languageArray = ['English', 'Non-English'];
  languageSelected = 'Non-English';

  functionList: any = ['GKnowledge'];
  functionSelected: any;

  chatPayload: any;
  chatData: any = [];
  resetChatStatus: boolean = false;
  subscription!: Subscription;
  chatSubscriber!: Subscription;


  currentBot: any;
  intervalId: any;
  chatEvent: any;

  stopChatCount = 0;
  modalRef: any;
chkagree: boolean = false;
  constructor(
    private sopService: SopService,
    private modalService: BsModalService,
    private toastMessage: ToastMessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params: any) => {
      if (params?.id) {
        this.functionSelected = params?.id;
        this.currentBot = params?.id;
        this.openBot();
      }
    })
  }

  ngOnInit(): void {
    this.getUserRole();
    // this.onLoading();
  }

  openBot() {
    this.chatPayload = null;
    this.chatData = [];
    this.resetChatStatus = true;
    this.chatPayload = this.functionList.find((ele: any) => ele?.value == this.functionSelected);

  }

  getFunctionParameters() {
    this.router.navigate(['konverse', this.functionSelected]);
    this.openBot();
  }

  getUserRole() {
    this.subscription = this.sopService.activeUserRole.subscribe(
      (res: any) => {
        if (res?.all_roles) {
          this.functionList = res['all_roles'] || [];

          if (res['all_roles']?.length) this.functionSelected = this.currentBot ? this.currentBot : res['all_roles'][0]['value'];

          this.chatPayload = null;
          this.chatData = [];
          if (this.currentBot) {
            this.openBot();
          }
        }
      });
  }

  getResponse(event: any) {
    console.log(event);
    this.chatEvent = event;
    if (event?.back) {
      this.chatPayload = null;
      return;
    }

    if (event?.unSubscription) {
      this.chatSubscriber?.unsubscribe();
      this.stopInterval();
      return;
    }

    this.resetChatStatus = false;
    this.chatSubscriber = this.sopService.complanceChat(event?.bot, event?.data, event?.source)
      .subscribe(
        (res: any) => {
          if (res?.response?.status == "success") {
            // this.toastMessage.showSuccess(res?.response?.message);
            this.startInterval();
          } else {
            this.toastMessage.showError(res?.response?.message);
          }

          // this.resetChatStatus = true;
        },
        (error) => {
          this.toastMessage.showError(error?.message);
          // this.resetChatStatus = true;
        }
      );
  }


  // checking status of file per INterver till successed

  startInterval(): void {
    this.intervalId = setInterval(() => {
      this.getUsersChatData();
    }, 15000); // 15 seconds in milliseconds
  }

  stopInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.resetChatStatus = true;
      this.stopChatCount = 0;
    }
  }

  getUsersChatData() {
    this.sopService.complanceChatStats()
      .subscribe(
        (res: any) => {
          if (res?.status == 'success') {
            this.stopChatCount++;
            if (res.data !== 0) {
              this.stopInterval();
              let content = '';
              content = content + res?.data || 'Something went wrong';

              // add to database api
              let prompt = this.chatData[this.chatData?.length - 1];
              this.createBotHistroy(prompt?.content, content, this.chatEvent?.source)
              this.scrollToBottom();


              let resultResponse = { "role": "assistant", "content": content };
              this.chatData.push(resultResponse);
            }

            if (this.stopChatCount > 12) {
              this.toastMessage.showWarning('Please try again later.');
              this.stopInterval();
            }

          } else {
            this.toastMessage.showError(res?.message);
            this.stopInterval();
          }

        },
        (error) => {
          // Handle the error here
          this.stopInterval();
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
  }

  createBotHistroy(prompt: any, answer: any, source = '') {
    const payload = {
      function_type: this.functionList.find((ele: any) => ele?.value == this.functionSelected)?.name,
      prompt,
      answer,
      sources: source || 'sources',
      likes: 0,
      language: this.languageSelected,
    };

    this.sopService.createUserHistory(payload).subscribe(
      (response: any) => console.log(response),
      (error: any) => console.error(error)
    );
  }

  // scoroll to bottom in chat window
  scrollToBottom() {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 0);
  }


  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.stopInterval();
  }
  // onLoading() {
  //   const modalbtn = document.getElementById('soc_report_policy_model') as HTMLLIElement;
  //   modalbtn.click();
  // }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
  }
}
